<template>
  <div class="contacts-group-details-view">
    <HubContactsGroupsDetail name="contact-group-detail" v-if="loaded" />
  </div>
</template>

<script>
import HubContactsGroupsDetail from '@blocks/hubs/HubContactsGroupsDetail'
export default {
  name: 'ContactsGroupDetailsView',
  components: {
    HubContactsGroupsDetail
  },
  data () {
    return {
      loaded: false
    }
  },
  created () {
    this.loaded = true
  }
}
</script>

<style lang="scss">

</style>
