<template>
  <div class="group-teams-board">
    <div class="group-teams-board__item" v-for="item in items" :key="item.role">
      <span class="group-teams-board__item__role">{{item.role}} : </span>
      <span class="group-teams-board__item__name">{{item.name}}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'GroupTeamsBoard',
  props: {
    items: {
      type: Array,
      required: true
    }
  }
}
</script>

<style lang="scss">
  .group-teams-board {
    @media print {
      background-color: transparent;
      color: black;
      flex-direction: column;
    }
    display: flex;
    justify-content: space-between;
    border-radius: .4rem;
    background-color: colors(primary);
    border-left: 6px solid shade(colors(primary), 40%);
    padding: 2rem;
    color: colors("background");
    font-size: 2.2rem;
    font-weight: font-weights(medium);

    &__item {
      &__role {
        text-transform: uppercase;
      }
    }
  }
</style>
