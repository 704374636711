<template>
  <ContainerHub class="hub-contact-group-detail">
    <!-- Header Slot -->
    <template slot="header">
      <HubHeader
        :titleTag="'h1'"
        :title="title"
        :email="email"
        :description="description"
      />
    </template>

    <!-- Body Slot -->
    <template slot="body">
      <GroupTeamsBoard v-if="hasBoard" :items="boardItems" />

      <HubEventsList
        :name="'past-events'"
        :items="past.items"
        :title="past.title"
        :buttonCaption="$t('message.common.buttons.morePastEvents')"
        v-if="hasPastEvent"
      />

      <HubEventsList
        :name="'upcoming-events'"
        :items="upcoming.items"
        :title="upcoming.title"
        :buttonCaption="$t('message.common.buttons.moreUpcomingEvents')"
        v-if="hasUpComingEvent"
      />

      <GroupTeams
        :content="item"
        :key="item.guid"
        v-for="item in groupItems"
      />
    </template>

  </ContainerHub>
</template>

<script>
import { mapGetters } from 'vuex'
import ContainerHub from '@containers/ContainerHub'
import HubHeader from './HubHeader'
import HubEventsList from './HubEventsList'
import GroupTeamsBoard from '@blocks/groups/GroupTeamsBoard'
import GroupTeams from '@blocks/groups/GroupTeams'

export default {
  name: 'HubContactsGroupsDetail',
  props: {
    name: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapGetters({
      title: 'page/getTitle',
      description: 'page/getDescription',
      email: 'page/getEmail',
      boardItems: 'board/getItems',
      groupItems: 'groups/getItems',
      past: 'events/getPast',
      upcoming: 'events/getUpcoming'
    }),
    hasBoard () {
      return this.boardItems.length
    },
    hasPastEvent () {
      return this.past.items.length
    },
    hasUpComingEvent () {
      return this.upcoming.items.length
    }

  },
  components: {
    HubHeader,
    ContainerHub,
    GroupTeamsBoard,
    GroupTeams,
    HubEventsList
  }
}
</script>

<style lang="scss">
  .hub-contact-group-detail{

    // Links

    &__links {
      @include media("small-medium"){
        width: calc(100% + #{layout("spacing")});
        margin-left: - layout("spacing")/2;
        margin-right: - layout("spacing")/2;
      }
      @include media("large"){
        width: calc(100% + #{layout("spacing")*2});
        margin-left: - layout("spacing");
        margin-right: - layout("spacing");
      }

      display: flex;
      justify-content: center;

      .group-teams-items {
        flex: 1 0 auto
      }
    }

    // Events

    &__events {

      // Events | Header

      &__header {
        border-bottom: 1px solid colors(border);
        margin-bottom: 2rem;

        h2 {
          font-weight: font-weights(normal);
          font-size: 2.4rem;
          margin: 0 0 .5rem 0;
          padding: 0;
        }
      }
    }

    // Group

    .group{
      @media print {
        display: none;
      }
    }
  }
</style>
